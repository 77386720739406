<template>
  <FilterOverview :showFilterInitially="false" title="Aufpreisrechner">
    <template #table="{ tableHeight }">
      <div class="d-flex w-100 justify-content-center">
        <div v-if="isLoading"><b-spinner label="Spinning"></b-spinner></div>
        <div class="card p-4" v-else-if="!reisetermin">
          <h5>Reisetermin nicht gefunden!</h5>
        </div>
        <div class="card card-body" style="max-width: 850px" v-else>
          <div class="text-center">
            <h3>{{ reisetermin?.reise?.reisekuerzel }} - {{ startdatum }}</h3>
          </div>
          <b-badge style="position: absolute; right: 2.25rem" v-if="hasInlandsfluege" variant="danger">
            <div>Der Termin beinhaltet Inlandsflüge über SKR!</div>
            <div>Bitte bei VB den Q1 INFL in Blank setzen.</div>
          </b-badge>
          <div class="d-flex mt-2" style="justify-content: flex-end" v-if="hasSpecificStatus">
            <b-button variant="primary" size="sm" @click="openAirlineExcel">Airline-Infos</b-button>
          </div>

          <div class="d-flex align-items-center mt-10" style="gap: 8px">
            <h5 class="m-0">Fluginformationen</h5>
            <b-badge
              class="bigger-font"
              style="height: 32px; display: flex; justify-content: center; align-items: center"
              :variant="isResGrp ? 'danger' : ''"
            >
              {{ einkaufsart }}
            </b-badge>
          </div>
          <div class="p-2 bigger-font" style="display: flex; gap: 12px">
            <table style="border-radius: 8px">
              <tr v-for="(item, i) in fluginformationenTableDefinition" :key="'tableRow-' + i">
                <td
                  v-for="(cell, j) in item"
                  :key="'tableRow-' + i + '_tableCell-' + j"
                  :style="cell.style"
                  :class="cell.class"
                >
                  {{ cell.string }}
                </td>
              </tr>
            </table>
          </div>
          <span v-if="isResGrp">
            <h5>
              <b-badge variant="danger">Nur RES GRP erlaubt!</b-badge>
            </h5>
          </span>
          <div class="d-flex align-items-center mt-6" style="gap: 8px">
            <h5 class="m-0">Wunschverbindung</h5>
          </div>
          <div class="p-2 bigger-font" style="display: flex; gap: 12px">
            <table style="border-radius: 8px">
              <tr>
                <td></td>
                <td class="" style="font-weight: bold">Hinflug</td>
                <td class="" style="font-weight: bold">Rückflug</td>
              </tr>
              <tr>
                <td class="row-description">Ankunft / Abflug</td>
                <td
                  :style="{
                    backgroundColor: backgroundColorAnkunft,
                  }"
                >
                  <DatePicker
                    v-model="ankunft"
                    type="datetime"
                    format="DD.MM.YYYY HH:mm"
                    :popup-style="{ top: '100%', left: 0 }"
                    :append-to-body="false"
                    :default-value="defaultValueAnkunft"
                    :lang="langConfig"
                  />
                </td>
                <td
                  :style="{
                    backgroundColor: backgroundColorAbflug,
                  }"
                >
                  <DatePicker
                    v-model="abflug"
                    type="datetime"
                    format="DD.MM.YYYY HH:mm"
                    :popup-style="{ top: '100%', left: 0 }"
                    :append-to-body="false"
                    :default-value="defaultValueAbflug"
                    :lang="langConfig"
                  />
                </td>
              </tr>
              <tr v-if="showFraVsNichtFraHin || showFraVsNichtFraRueck">
                <td class="row-description">
                  FRA vs.
                  <s>FRA</s>
                </td>
                <td
                  v-if="showFraVsNichtFraHin"
                  :style="{
                    backgroundColor: fraHinflug == null ? '#F64E6050' : '#1BC5BD50',
                  }"
                >
                  <b-button
                    size="sm"
                    class="mr-1 bigger-font"
                    @click="fraHinflug = true"
                    :variant="fraHinflug === true ? 'primary' : ''"
                  >
                    FRA
                  </b-button>
                  <b-button
                    size="sm"
                    class="bigger-font"
                    @click="fraHinflug = false"
                    :variant="fraHinflug === false ? 'primary' : ''"
                  >
                    <s>FRA</s>
                  </b-button>
                </td>
                <td v-else></td>
                <td
                  v-if="showFraVsNichtFraRueck"
                  :style="{ backgroundColor: fraRueckflug == null ? '#F64E6050' : '#1BC5BD50' }"
                >
                  <b-button
                    size="sm"
                    class="mr-1 bigger-font"
                    @click="fraRueckflug = true"
                    :variant="fraRueckflug === true ? 'primary' : ''"
                  >
                    FRA
                  </b-button>
                  <b-button
                    size="sm"
                    class="bigger-font"
                    @click="fraRueckflug = false"
                    :variant="fraRueckflug === false ? 'primary' : ''"
                  >
                    <s>FRA</s>
                  </b-button>
                </td>
              </tr>
              <tr>
                <td class="row-description">Vorverlängerung / Verlängerung</td>
                <td>
                  <b-form-select
                    size="sm"
                    class="bigger-font"
                    style="width: 210px"
                    v-model="vorverlaengerungSelected"
                    :options="
                      Array.from(
                        { length: vorverlaengerung ? vorverlaengerung + 1 : 1 },
                        (value, index) => index
                      )
                    "
                  ></b-form-select>
                </td>
                <td>
                  <b-button
                    size="sm"
                    class="mr-1 bigger-font"
                    @click="verlaengerungSelected = true"
                    :variant="verlaengerungSelected === true ? 'primary' : ''"
                  >
                    Ja
                  </b-button>
                  <b-button
                    size="sm"
                    class="bigger-font"
                    @click="verlaengerungSelected = false"
                    :variant="verlaengerungSelected === false ? 'primary' : ''"
                  >
                    Nein
                  </b-button>
                </td>
              </tr>
            </table>
          </div>
          <div class="p-2 mt-6 bigger-font">
            <table>
              <tr>
                <td class="row-description">EK p.P.</td>
                <td
                  :style="{
                    backgroundColor:
                      einkaufspreisProPerson == null || einkaufspreisProPerson == ''
                        ? '#F64E6050'
                        : '#1BC5BD50',
                  }"
                >
                  <b-input-group size="sm" append="€" style="width: 210px">
                    <b-form-input
                      class="bigger-font"
                      v-model="einkaufspreisProPerson"
                      type="number"
                      step="1"
                      min="0"
                      :number="true"
                    />
                  </b-input-group>
                </td>
              </tr>
              <tr style="height: 35px">
                <td class="row-description">TH Langstrecke p.P.</td>
                <td>
                  <b-badge class="bigger-font">
                    {{ reisetermin?.thLangstrecke ? reisetermin?.thLangstrecke + ' €' : '-' }}
                  </b-badge>
                </td>
              </tr>
              <tr style="height: 35px">
                <td class="row-description">VK Flug(auf)preis p.P.</td>
                <td>
                  <div class="d-flex">
                    <b-badge
                      :style="verkaufspreisProPerson ? 'font-size: 1.1rem; font-weight: bold' : ''"
                      :variant="verkaufspreisProPerson ? 'warning' : 'secondary'"
                    >
                      {{ verkaufspreisProPerson ? verkaufspreisProPerson + ' €' : '-' }}
                    </b-badge>
                    <b-badge
                      v-if="verkaufspreisProPerson"
                      v-b-popover.hover="verkaufspreisProPersonTerm()"
                      style="display: flex; justify-content: center; align-items: center"
                      class="ml-auto"
                    >
                      <i class="fas fa-info btn-secondary"></i>
                    </b-badge>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="mt-6 d-flex" style="gap: 8px">
            <div v-if="vorverlaengerung == null">
              <h5>
                <b-badge variant="danger">
                  {{ hinweisVorverlaengerungPflege }}
                </b-badge>
              </h5>
            </div>
            <div v-if="vorverlaengerungSelected > 0">
              <h5>
                <b-badge variant="danger">Sondertransfer Hin separat buchen!</b-badge>
              </h5>
            </div>
            <div v-if="verlaengerungSelected === true">
              <h5>
                <b-badge variant="danger">Sondertransfer Rück separat buchen!</b-badge>
              </h5>
            </div>
          </div>
          <div class="mt-1" v-if="verkaufspreisProPerson != null">
            <h5>
              <b-badge>
                <span v-html="labelAufpreisbadge"></span>
              </b-badge>
            </h5>
          </div>
        </div>
      </div>
    </template>
  </FilterOverview>
</template>
<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import * as odataService from '@/core/common/services/odata.service';
import { einkaufsartOptions } from '@/core/common/helpers/utils.js';
import { add, format, isAfter, isBefore, isWithinInterval, set, setHours, setMinutes, sub } from 'date-fns';
import DatePicker from 'vue2-datepicker';
const moment = require('moment');

export default {
  components: {
    FilterOverview,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      reisetermin: null,
      fluginformationenTableDefinition: null,
      ankunft: null,
      abflug: null,
      fraHinflug: null,
      fraRueckflug: null,
      vorverlaengerungSelected: 0,
      verlaengerungSelected: false,
      hinweisVorverlaengerungPflege: '"Vorverlängerung" in Reisestammdaten pflegen!',
      einkaufspreisProPerson: null,
      langConfig: {
        formatLocale: {
          months: moment.localeData().months(),
          monthsShort: moment.localeData().monthsShort(),
          weekdays: moment.localeData().weekdays(),
          weekdaysShort: moment.localeData().weekdaysShort(),
          weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          firstDayOfWeek: 1,
        },
      },
    };
  },
  async mounted() {
    if (this.$route.query.reiseterminId) {
      const { reiseterminId } = this.$route.query;
      this.isLoading = true;
      this.reisetermin = await this.getReisetermin(reiseterminId);
      this.fluginformationenTableDefinition = [
        [
          { string: '', style: '', class: '' },
          { string: 'Hinflug', style: 'font-weight: bold', class: 'bigger-font' },
          { string: 'Rückflug', style: 'font-weight: bold', class: '' },
        ],
        [
          { string: 'ZL Zielflughafen / Abflughafen', style: '', class: 'row-description' },
          { string: this.ziellandZielflughaefen, style: '', class: '' },
          { string: this.ziellandAbflughaefen, style: '', class: '' },
        ],
        [
          { string: 'Früh. Ankunft / Abflug', style: '', class: 'row-description' },
          { string: this.fruehesteAnkunftDateFormatted, style: '', class: '' },
          { string: this.fruehesterAbflugDateFormatted, style: '', class: '' },
        ],
        [
          { string: 'Spät. Ankunft / Abflug', style: '', class: 'row-description' },
          { string: this.spaetesteAnkunftDateFormatted, style: '', class: '' },
          { string: this.spaetesterAbflugDateFormatted, style: '', class: '' },
        ],

        [
          { string: 'TF Szenario', style: '', class: 'row-description' },
          { string: this.transferszenarioHin, style: '', class: '' },
          { string: this.transferszenarioRueck, style: '', class: '' },
        ],
        [
          { string: 'GRP FH', style: '', class: 'row-description' },
          { string: this.gruppeFlughafen, style: '', class: '' },
          { string: '', style: '', class: '' },
        ],
        [
          { string: 'Flugnr. GRP', style: '', class: 'row-description' },
          { string: this.flugnummerGruppeHin, style: '', class: '' },
          { string: this.flugnummerGruppeRueck, style: '', class: '' },
        ],
        [
          { string: 'GRP PNR', style: '', class: 'row-description' },
          { string: this.gruppePnr, style: '', class: '' },
          {
            string: '',
            style: '',
            class: '',
          },
        ],
      ];
      this.isLoading = false;
    }
  },
  computed: {
    labelAufpreisbadge() {
      const tsHin = this.transferszenarioHin?.substring(0, 2);
      const t4Label =
        'VK gilt nur für den Flug! Kein Transfer inklusive. Bitte VK Flug(auf)preis und Flug-EK in die BLANK Buchung setzen. <br/>Ggf. separaten Transfer buchen!';
      const txLabel =
        'VK gilt nur für den Flug! Kein Transfer inklusive. Bitte VK Flug(auf)preis und Flug-EK in die BLANK Buchung setzen.';
      const standardLabel =
        'Bitte VK Flug(auf)preis und Flug-EK in die BLANK Buchung setzen. Transfer-EK folgt durch DOM.';
      return tsHin === 'T4' ? t4Label : tsHin === 'TX' ? txLabel : standardLabel;
    },
    backgroundColorAnkunft() {
      if (this.vorverlaengerungSelected !== 0) return;
      return this.ankunft == null || this.ankunftNotInInterval ? '#F64E6050' : '#1BC5BD50';
    },
    backgroundColorAbflug() {
      if (this.verlaengerungSelected === true) return;

      return this.abflug == null || this.abflugNotInInterval ? '#F64E6050' : '#1BC5BD50';
    },
    verkaufspreisProPerson() {
      if (this.reisetermin) {
        const isFern = !['Dach', 'Südeuropa', 'Nordeuropa'].includes(this.reisetermin?.reise?.region);
        const vkKalkVornacht = this.beruecksichtigeVkKalkVornacht
          ? Math.round((this.reisetermin.metadata.vkVornachtDzProPerson ?? 0) * 10) / 10
          : 0;
        const vkTranferkostenHin = this.beruecksichtigeTransferkostenVkHin
          ? Math.round((this.reisetermin.metadata.vkTransferkostenHin ?? 0) * 10) / 10
          : 0;
        const vkTranferkostenRueck = this.beruecksichtigeTransferkostenVkRueck
          ? Math.round((this.reisetermin.metadata.vkTransferkostenRueck ?? 0) * 10) / 10
          : 0;
        const vorverlaengerungOrAnkunftValid =
          this.vorverlaengerungSelected > 0 ||
          (this.ankunft &&
            !this.ankunftNotInInterval &&
            (!this.showFraVsNichtFraHin || this.fraHinflug != null));
        const verlaengerungOrAbflugValid =
          this.verlaengerungSelected ||
          (this.abflug &&
            !this.abflugNotInInterval &&
            (!this.showFraVsNichtFraRueck || this.fraRueckflug != null));
        const multiplier = isFern ? 0.74 : 0.76;
        if (
          vorverlaengerungOrAnkunftValid &&
          verlaengerungOrAbflugValid &&
          this.einkaufspreisProPerson != null &&
          this.einkaufspreisProPerson != ''
        ) {
          const roundedVk = Math.round(
            (this.einkaufspreisProPerson - (this.reisetermin.thLangstrecke ?? 0)) / multiplier +
              vkKalkVornacht +
              vkTranferkostenHin +
              vkTranferkostenRueck
          );
          const minZero = roundedVk < 0 ? 0 : roundedVk;
          return minZero.toString().replaceAll('.', ',');
        }
      }
      return null;
    },

    ankunftNotInInterval() {
      if (
        this.ankunft != null &&
        (!isAfter(add(this.ankunft, { minutes: 1 }), this.fruehesteAnkunftDate) ||
          !isBefore(sub(this.ankunft, { minutes: 1 }), this.spaetesteAnkunftDate))
      ) {
        return true;
      }

      return false;
    },
    abflugNotInInterval() {
      if (
        this.abflug != null &&
        (!isAfter(add(this.abflug, { minutes: 1 }), this.fruehesterAbflugDate) ||
          !isBefore(sub(this.abflug, { minutes: 1 }), this.spaetesterAbflugDate))
      ) {
        return true;
      }

      return false;
    },
    ankunftBeforeThree() {
      if (this.ankunft != null && this.fruehesteAnkunftDate != null && this.spaetesteAnkunftDate != null) {
        const timeToCheckFirstDay = setMinutes(setHours(this.fruehesteAnkunftDate, 3), 0);
        const timeToCheckSecondDay = setMinutes(setHours(this.spaetesteAnkunftDate, 3), 0);
        const interval = {
          start: this.fruehesteAnkunftDate,
          end: this.spaetesteAnkunftDate,
        };

        const isThreeOClockInIntervalFirstDay = isWithinInterval(timeToCheckFirstDay, interval);
        if (isThreeOClockInIntervalFirstDay) {
          return isWithinInterval(this.ankunft, {
            start: this.fruehesteAnkunftDate,
            end: timeToCheckFirstDay,
          });
        }

        const isThreeOClockInIntervalSecondDay = isWithinInterval(timeToCheckSecondDay, interval);
        if (isThreeOClockInIntervalSecondDay) {
          return isWithinInterval(this.ankunft, {
            start: this.fruehesteAnkunftDate,
            end: timeToCheckSecondDay,
          });
        }
      }

      return false;
    },
    startdatum() {
      if (!this.reisetermin) return '';
      return format(new Date(this.reisetermin?.startdatum), 'dd.MM.yyyy');
    },
    einkaufsart() {
      if (!this.reisetermin || !this.reisetermin.flugeinkauf) {
        return 'EP';
      }
      const resGrpEnums = ['RestriktivBestaetigtBuchbar', 'LeerverkaufRes', 'RestriktivStattBackup'];
      const flexGrpEpEnums = ['FlexBuchbar', 'LeerverkaufFlex'];

      return resGrpEnums.includes(this.reisetermin.flugeinkauf.einkaufsart)
        ? 'RES GRP'
        : flexGrpEpEnums.includes(this.reisetermin.flugeinkauf.einkaufsart)
        ? 'FLEX GRP / EP'
        : 'EP';
    },
    ziellandAbflughaefen() {
      if (!this.reisetermin || !this.reisetermin.metadata?.ziellandAbflughaefen) return '-';

      return this.reisetermin.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode).join(', ');
    },
    ziellandZielflughaefen() {
      if (!this.reisetermin || !this.reisetermin.metadata?.ziellandZielflughaefen) return '-';

      return this.reisetermin.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode).join(', ');
    },
    fruehesteAnkunftDate() {
      if (!this.reisetermin || !this.reisetermin.metadata?.fruehesteAnkunft) return null;

      const { startdatum } = this.reisetermin;
      const { fruehesteAnkunft } = this.reisetermin.metadata;

      const date = new Date(startdatum);
      const time = { hours: fruehesteAnkunft.substring(0, 2), minutes: fruehesteAnkunft.substring(3, 5) };

      return set(date, time);
    },
    fruehesteAnkunftDateFormatted() {
      if (!this.fruehesteAnkunftDate) return '-';

      return format(this.fruehesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    defaultValueAnkunft() {
      if (!this.fruehesteAnkunftDate) return '-';

      return this.fruehesteAnkunftDate;
    },
    defaultValueAbflug() {
      if (!this.fruehesterAbflugDate) return '-';

      return this.fruehesterAbflugDate;
    },
    fruehesterAbflugDate() {
      if (!this.reisetermin || !this.reisetermin.metadata?.fruehesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.reisetermin;
      const { fruehesterAbflug } = this.reisetermin.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: fruehesterAbflug.substring(0, 2), minutes: fruehesterAbflug.substring(3, 5) };

      return set(date, time);
    },
    fruehesterAbflugDateFormatted() {
      if (!this.fruehesterAbflugDate) return '-';

      return format(this.fruehesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesteAnkunftDate() {
      if (!this.reisetermin || !this.reisetermin.metadata?.spaetesteAnkunft) return null;
      const { startdatum } = this.reisetermin;
      const { spaetesteAnkunft, restriktionenHinRollover } = this.reisetermin.metadata;

      const date = new Date(startdatum);
      const time = { hours: spaetesteAnkunft.substring(0, 2), minutes: spaetesteAnkunft.substring(3, 5) };

      return add(set(date, time), { days: restriktionenHinRollover ?? 0 });
    },
    spaetesteAnkunftDateFormatted() {
      if (!this.spaetesteAnkunftDate) return '-';

      return format(this.spaetesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesterAbflugDate() {
      if (!this.reisetermin || !this.reisetermin.metadata?.spaetesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.reisetermin;
      const { spaetesterAbflug, restriktionenRueckRollover } = this.reisetermin.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: spaetesterAbflug.substring(0, 2), minutes: spaetesterAbflug.substring(3, 5) };

      return add(set(date, time), { days: restriktionenRueckRollover ?? 0 });
    },
    spaetesterAbflugDateFormatted() {
      if (!this.spaetesterAbflugDate) return '-';

      return format(this.spaetesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
    transferszenarioHin() {
      if (!this.reisetermin || !this.reisetermin.metadata?.transferszenarioHin) return '-';
      return this.reisetermin.metadata.transferszenarioHin;
    },
    transferszenarioRueck() {
      if (!this.reisetermin || !this.reisetermin.metadata?.transferszenarioRueck) return '-';
      return this.reisetermin.metadata.transferszenarioRueck;
    },
    gruppeFlughafen() {
      if (!this.reisetermin || !this.reisetermin.flugeinkauf) return '-';

      return this.reisetermin.flugeinkauf.gruppeFlughafenIataCode ?? '-';
    },
    flugnummerGruppeHin() {
      if (!this.reisetermin || !this.reisetermin.flugeinkauf) return '-';

      return this.reisetermin.flugeinkauf.flugnummerGruppeHin ?? '-';
    },
    flugnummerGruppeRueck() {
      if (!this.reisetermin || !this.reisetermin.flugeinkauf) return '-';

      return this.reisetermin.flugeinkauf.flugnummerGruppeRueck ?? '-';
    },
    gruppePnr() {
      if (!this.reisetermin || !this.reisetermin.flugeinkauf) return '-';

      return this.reisetermin.flugeinkauf.gruppePnr ?? '-';
    },
    isResGrp() {
      return this.einkaufsart === 'RES GRP';
    },
    showFraVsNichtFraHin() {
      if (!this.reisetermin || this.vorverlaengerungSelected > 0) return false;
      return this.transferszenarioHin?.substring(0, 2) === 'T6';
    },
    showFraVsNichtFraRueck() {
      if (!this.reisetermin || this.verlaengerungSelected === true) return false;
      return this.transferszenarioRueck?.substring(0, 2) === 'T6';
    },
    beruecksichtigeTransferkostenVkHin() {
      return this.showFraVsNichtFraHin && this.fraHinflug === false && this.vorverlaengerungSelected === 0;
    },
    beruecksichtigeTransferkostenVkRueck() {
      return this.showFraVsNichtFraRueck && this.fraRueckflug === false && !this.verlaengerungSelected;
    },
    beruecksichtigeVkKalkVornacht() {
      return this.vorverlaengerungSelected === 0 && this.ankunftBeforeThree;
    },
    vorverlaengerung() {
      if (!this.reisetermin || !this.reisetermin.metadata) return '';

      return this.reisetermin.metadata.vorverlaengerung;
    },
    hasInlandsfluege() {
      if (this.reisetermin && ['NurSkr', 'Gemischt'].includes(this.reisetermin.metadata.inlandsfluegeEinkauf))
        return true;

      return false;
    },
    hasSpecificStatus() {
      if (this.einkaufsart === 'FLEX GRP / EP' || this.einkaufsart === 'RES GRP') {
        return true;
      }
      return false;
    },
  },
  methods: {
    verkaufspreisProPersonTerm() {
      if (this.reisetermin) {
        const isFern = !['Dach', 'Südeuropa', 'Nordeuropa'].includes(this.reisetermin?.reise?.region);
        const multiplier = isFern ? 0.74 : 0.76;
        const vkKalkVornacht = this.beruecksichtigeVkKalkVornacht
          ? Math.round((this.reisetermin.metadata.vkVornachtDzProPerson ?? 0) * 10) / 10
          : 0;
        const vkTranferkostenHin = this.beruecksichtigeTransferkostenVkHin
          ? Math.round((this.reisetermin.metadata.vkTransferkostenHin ?? 0) * 10) / 10
          : 0;
        const vkTranferkostenRueck = this.beruecksichtigeTransferkostenVkRueck
          ? Math.round((this.reisetermin.metadata.vkTransferkostenRueck ?? 0) * 10) / 10
          : 0;

        return `((${this.einkaufspreisProPerson} € - ${
          this.reisetermin.thLangstrecke ?? 0
        } €) / ${multiplier}) ${vkKalkVornacht > 0 ? '+ ' + vkKalkVornacht + ' €' : ''} ${
          vkTranferkostenHin > 0 ? '+ ' + vkTranferkostenHin + ' €' : ''
        } ${vkTranferkostenRueck > 0 ? '+ ' + vkTranferkostenRueck + ' €' : ''}`.replaceAll('.', ',');
      }
      return null;
    },
    getReisetermin(reiseterminId) {
      const filter = { id: +reiseterminId };
      const expand = {
        reise: {},
        metadata: {
          expand: {
            ziellandAbflughaefen: {},
            ziellandZielflughaefen: {},
            inlandsfluege: {},
          },
        },
        flugeinkauf: {},
      };
      return odataService.getReisetermin({ filter, expand }).then(res => res.data[0]);
    },
    openAirlineExcel() {
      window.open(
        'https://skrreisengmbh-my.sharepoint.com/:x:/g/personal/lena_bedorf_skr_de/EQjt353yT7FEnRZgUUtALKsBhiEaUQSmS6MsNHXTgxxOvw?e=hIdlMb',
        '_blank'
      );
    },
  },
};
</script>
<style scoped>
td {
  padding: 4px 8px;
  width: 250px;
}
tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.row-description {
  background-color: #e5eaee;
}
.bigger-font {
  font-size: 1.1rem;
}
.custom-select,
.form-control {
  border: 1px solid #ccc;
}
</style>
